export function sidebar()
{
    const sidebar = document.getElementById("js_sidebar");
    const sidebarToggle = document.getElementById("js_sidebar_toggle");
    const bodyElement = document.body;

    if (sidebarToggle && sidebar) {
        sidebarToggle.addEventListener('click', function () {
            bodyElement.classList.toggle('sidebar--opened');
            sidebarToggle.classList.toggle('is-active');
            if (sidebar.classList.contains('show')) {
                closeSidebar();
            } else {
                showSidebar();
            }
        });

        const showSidebar = () => {
            sidebar.classList.add('show')
            const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
            bodyElement.style.top = `-${scrollY}`;
        };
        const closeSidebar = () => {
            const scrollY = bodyElement.style.top;
            bodyElement.style.position = '';
            bodyElement.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
            sidebar.classList.remove('show');
        }

        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
        });
    }
}
