import {search} from "./search.js";
import {sidebar} from "./sidebar.js";
import {menu} from "./menu.js";
import {article} from "./article.js";
import {header} from "./header.js";

export function app()
{
    // Шапка
    header();
    // Поиск
    search();
    // Сайдбар
    sidebar();
    // Меню
    menu();
    // Статья
    article();
}
