import autoComplete from "@tarekraafat/autocomplete.js";

export function search()
{
    const search = document.getElementById("js_search_input");
    const mobileSearchButton = document.getElementById("js_open_mobile_search");
    const bodyElement = document.body;
    if (search) {
        const searchAutoComplete = new autoComplete({
            selector: "#js_search_input",
            searchEngine: 'loose',
            threshold: 3,
            placeHolder: "Поиск",
            data: {
                src: async(query) => {
                    try {
                        const source = await fetch(`/wp-json/wp/v2/search/?search=${query}`);
                        return await source.json();
                    } catch (error) {
                        return error;
                    }
                },
                keys: ['title'],
            },
            events: {
                input: {
                    blur: () => {
                        if (searchAutoComplete.input.value.length === 0) {
                            bodyElement.classList.toggle('search-mobile--opened');
                        }
                    }
                },
            },
            resultsList: {
                maxResults: 10,
                noResults: true,
                element: (list, data) => {
                    if (!data.results.length) {
                        const message = document.createElement("div");
                        message.setAttribute("class", "no-search-result");
                        message.innerHTML = `<span>По вашему запросу ничего не найдено</span>`;
                        list.prepend(message);
                    }
                },
            },
            resultItem: {
                element: (item, data) => {
                    item.innerHTML = `<a href="${data.value.url}">${data.match}</a>`;
                },
                highlight: true,
            },
        });

        mobileSearchButton.addEventListener('click', function () {
            bodyElement.classList.toggle('search-mobile--opened');
            searchAutoComplete.input.focus();
        });
    }
}
