import SimpleBar from 'simplebar'
import ResizeObserver from 'resize-observer-polyfill';

export function menu()
{
    const sidebarMenu = document.getElementById("js_sidebar_menu");

    if (sidebarMenu) {
        const sidebarInner = document.getElementById("js_sidebar_inner");
        const sidebarLogo = document.getElementById("js_sidebar_logo");
        const sidebarBottom = document.getElementById("js_sidebar_bottom");
        const navigationSections = sidebarMenu.querySelectorAll(".has-children");
        const navigationSectionsGroups = sidebarMenu.querySelectorAll(".navigation-section-collapse");

        // Инициализация плагина SimpleBar для меню
        new SimpleBar(sidebarMenu, { autoHide: false });

        // Установка высоты для меню
        const breakpoint = window.matchMedia("(max-width: 639px)")
        const setMenuHeight = function (x) {
            if (x.matches) { // If media query matches
                sidebarMenu.style.height = `${sidebarInner.offsetHeight - sidebarLogo.offsetHeight - sidebarBottom.offsetHeight - 30}px`;
            } else {
                sidebarMenu.style.height = `${sidebarInner.offsetHeight - sidebarLogo.offsetHeight - sidebarBottom.offsetHeight - 90}px`;
            }
        }
        setMenuHeight(breakpoint);
        breakpoint.addEventListener("change", function () {
            setMenuHeight(breakpoint);
        });

        if (navigationSections.length > 0 || navigationSectionsGroups.length > 0) {
            for (let i = 0; i < navigationSections.length; i++) {
                navigationSections[i].addEventListener('click', function (e) {
                    e.preventDefault();
                    this.classList.toggle('navigation-section--open');
                });
            }

            for (let i = 0; i < navigationSectionsGroups.length; i++) {
                navigationSectionsGroups[i].addEventListener('click', function (e) {
                    e.preventDefault();
                    this.parentNode.classList.toggle('navigation-section--open');
                });
            }
        }

        window.ResizeObserver = ResizeObserver;
    }
}
