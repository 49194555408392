export function header()
{
    let lastScrollTop = 0;

    window.addEventListener('scroll', function () {
        let currentScroll = window.pageYOffset || document.documentElement.scrollTop;

        if (currentScroll === 0) {
            document.body.classList.add('scrolling-to-top');
        } else {
            document.body.classList.remove('scrolling-to-top');
        }

        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll
    });
}
